import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, ButtonLink } from '../elements'

import awardsItemBG from '../../images/teachers/awards_item_bg.svg'


const AwardsWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 30px;
  padding-bottom: 70px;
  @media (max-width: 991px) {
    padding-bottom: 0;
    margin-top: 122px;
  }
`

const AwardsContainer = styled(Container)``

const AwardsTitleWrapper = styled(Col)`
  margin-bottom: 122px;

  @media (max-width: 991px) {
    display: none;
  }
`

const AwardsTitle = styled(TitleWithLine)``

const AwardsListOuterRowWrapper = styled(Row)``

const AwardsListItemsWrapper = styled(Col)`
  @media (max-width: 991px) {
    ${(props) =>
    props.name === props.active
      ? `
      display: block;
    `
      : `
      display: none;
    `}
  }
`

const AwardsListItemWrapper = styled(Col)`
  margin-bottom: 30px;
  position: relative;
  text-align: center;
  
  ${props => (props.position === 'topLeft' || props.position === 'topRight') ? `
    
  ` : `
    padding-top: 20px;
    padding-bottom: 20px;
  `}
  
  &:before,
  &:after {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    border-radius: 10px;
    @media (max-width: 575px) {
      top: 95px !important;
    }
  }
  &:before {
    top: 0%;
    background-color: ${(props) => props.theme.colorGreenBright};
    ${(props) =>
    (props.position === 'topLeft' || props.position === 'topRight') &&
    `
      content: '';
    `}
    ${(props) =>
    props.position === 'topLeft' &&
    `
      right: 15px;
    `}
    ${(props) =>
    props.position === 'topRight' &&
    `
      left: 15px;
    `}
    @media (min-width: 1200px) and (max-width: 1399px) {
      top: 0%;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      top: 0%;
    }
    @media (max-width: 991px) {
      top: 0%;
      left: 15px;
      right: 15px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      top: 0%;
    }
  }
  &:after {
    top: 0;
    overflow: hidden;
    background-image: url(${awardsItemBG});
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    ${(props) =>
    props.position === 'bottom' &&
    `
      content: '';
    `}
    @media (max-width: 991px) {
      top: 33%;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      top: 32.5%;
    }
  }
`

const AwardsListItemInnerWrapper = styled.div`
  position: relative;
  z-index: 10;
`

const AwardsListItemHeader = styled.div`
  position: relative;
  text-align: center;
  @media (min-width: 576px) {
    padding-left: 30%;
    padding-right: 30%;
  }
`

const AwardsListItemHeaderImageWrapper = styled.div`
  overflow: hidden;
  border-radius: 50%;
  @media (max-width: 575px) {
    display: inline-block;
    width: 160px;
  }
`

const AwardsListItemHeaderName = styled.p`
  position: absolute;
  top: 50%;
  left: 22%;
  right: 22%;
  background-color: ${(props) => props.theme.colorWhite};
  font-size: 15px;
  font-weight: 400;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  margin-bottom: 0;
  border: 1px solid ${(props) => props.theme.colorGrayMiddle};
  transform: translateY(-50%);
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 13px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 13px;
  }
  @media (max-width: 575px) {
    left: 15%;
    right: 15%;
    font-size: 11px;
  }
`

const AwardsListItemDescWrapper = styled.div`
  ${(props) =>
    props.position === 'top' &&
    `
    min-height: 220px;
  `}
  ${(props) =>
    props.position === 'bottom' &&
    `
    min-height: 140px;
  `}
  @media (min-width: 992px) and (max-width: 1199px) {
    ${(props) =>
    props.position === 'top' &&
    `
      min-height: 180px;
    `}
    ${(props) =>
    props.position === 'bottom' &&
    `
      min-height: 110px;
    `}
  }
  @media (max-width: 991px) {
    ${(props) =>
    props.position === 'top' &&
    `
      min-height: 200px;
    `}
    ${(props) =>
    props.position === 'bottom' &&
    `
      min-height: 110px;
    `}
  }
  @media (min-width: 576px) and (max-width: 767px) {
    ${(props) =>
    props.position === 'top' &&
    `
      min-height: 160px;
    `}
    ${(props) =>
    props.position === 'bottom' &&
    `
      min-height: 80px;
    `}
  }
  @media (max-width: 575px) {
    min-height: auto;
  }
`

const AwardsListItemDescTitle = styled(({ color, ...rest }) => <p {...rest} />)`
  font-size: 36px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 16px;

  ${(props) =>
    props.color &&
    `
    color: ${props.color};
  `}
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 30px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 30px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
  }
  span {
    display: block;
    font-size: 28px;
    font-weight: 400;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 23px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      font-size: 23px;
    }
    @media (max-width: 575px) {
      font-size: 23px;
    }
  }
`

const AwardsListItemDescText = styled.div`
  color: ${(props) => props.$color ? props.$color : props.theme.colorWhite};
  font-size: 20px;
  font-weight: ${(props) => props.$fontWeight ? props.$fontWeight : 400};
  margin: 0 auto;
  max-width: 90%;

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 16px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
  }

  strong {
    color: ${props => props.theme.colorGreenBright};
  }
`

const AwardsButtonLinkWrapper = styled(Col)`
  text-align: center;
  margin-top: 10px;
  @media (max-width: 991px) {
    display: none;
  }
`

const AwardsButtonLink = styled(ButtonLink)`
  padding-top: 8px;
  padding-bottom: 8px;
`

const MainAward = styled.div`
  overflow: hidden;
  position: relative;

  @media (max-width: 1199px) {
    border-radius: 8px;
  }

  ${AwardsListItemInnerWrapper} {
    bottom: 30px;
    position: absolute;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.8);

    @media (min-width: 992px) and (max-width: 1399px) {
      bottom: 0;
    }

    @media (max-width: 484px) {
      bottom: 0;
    }
  }
`

const Awards = (props) => {
  const { activeClass } = props

  const data = useStaticQuery(graphql`
    query {
      awardImage: file(relativePath: { eq: "teachers/award.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      awardMainImage: file(relativePath: { eq: "teachers/award_main_2024.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 656) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <AwardsWrapper id="prizes">
      <AwardsContainer>
        <Row>
          <AwardsTitleWrapper x={12}>
            <AwardsTitle color="#91BD24">
              <span>Nagrody</span>
            </AwardsTitle>
          </AwardsTitleWrapper>
        </Row>
        <AwardsListOuterRowWrapper>
          <AwardsListItemsWrapper
            name="class3"
            active={activeClass}
            xs={12}
            lg={6}
          >
            <Row>
              <AwardsListItemWrapper position="topLeft" xs={12}>
                <MainAward>
                  <Img
                    alt="Nagroda główna dla klas I-III"
                    fluid={data.awardMainImage.childImageSharp.fluid}
                  />

                  <AwardsListItemInnerWrapper>
                    <AwardsListItemDescWrapper position="top">
                      <AwardsListItemDescTitle color="#fff">
                        Nagroda główna
                        <span>
                          dla{'\u00A0'}klas{'\u00A0'}I-III
                        </span>
                      </AwardsListItemDescTitle>
                      <AwardsListItemDescText>
                        <p><strong>Miejsce{'\u00A0'}I</strong>: 3000{'\u00A0'}zł na{'\u00A0'}sfinansowanie wycieczki edukacyjnej oraz bilety do{'\u00A0'}miasteczka edukacyjnego minicity. Dodatkowo szkołę odwiedzi influencer, który przeprowadzi spotkanie edukacyjne.</p>
                        <p><strong>Miejsce{'\u00A0'}II</strong>: 3000{'\u00A0'}zł na{'\u00A0'}sfinansowanie wycieczki edukacyjnej oraz bilety do{'\u00A0'}miasteczka edukacyjnego minicity.</p>
                      </AwardsListItemDescText>
                    </AwardsListItemDescWrapper>
                  </AwardsListItemInnerWrapper>
                </MainAward>
              </AwardsListItemWrapper>
              <AwardsListItemWrapper position="bottom" xs={12}>
                <AwardsListItemInnerWrapper>
                  <AwardsListItemHeader>
                    <AwardsListItemHeaderImageWrapper>
                      <Img
                        fluid={data.awardImage.childImageSharp.fluid}
                        alt="Wizualizacja/zdjęcie nagrody"
                      />
                    </AwardsListItemHeaderImageWrapper>
                  </AwardsListItemHeader>
                  <AwardsListItemDescWrapper position="bottom">
                    <AwardsListItemDescTitle color="#00975F">
                      Nagroda II{'\u00A0'}stopnia
                      <span>
                        dla{'\u00A0'}klas{'\u00A0'}I-III
                      </span>
                    </AwardsListItemDescTitle>
                    <AwardsListItemDescText $color="#000" $fontWeight={200}>
                      Atrakcyjne nagrody dla<br />każdego dziecka oraz nauczyciela
                    </AwardsListItemDescText>
                  </AwardsListItemDescWrapper>
                </AwardsListItemInnerWrapper>
              </AwardsListItemWrapper>
            </Row>
          </AwardsListItemsWrapper>
          <AwardsListItemsWrapper
            name="class46"
            active={activeClass}
            xs={12}
            lg={6}
          >
            <Row>
              <AwardsListItemWrapper position="topRight" xs={12}>
                <MainAward>
                  <Img
                    fluid={data.awardMainImage.childImageSharp.fluid}
                    alt="Nagroda dłówna dla klas IV-VI"
                  />
                  <AwardsListItemInnerWrapper>
                    <AwardsListItemDescWrapper position="top">
                      <AwardsListItemDescTitle color="#fff">
                        Nagroda główna
                        <span>
                          dla{'\u00A0'}klas{'\u00A0'}IV-VI
                        </span>
                      </AwardsListItemDescTitle>
                      <AwardsListItemDescText>
                        <p><strong>Miejsce{'\u00A0'}I</strong>: 3000{'\u00A0'}zł na{'\u00A0'}sfinansowanie wycieczki edukacyjnej oraz bilety do{'\u00A0'}miasteczka edukacyjnego minicity. Dodatkowo szkołę odwiedzi influencer, który przeprowadzi spotkanie edukacyjne.</p>
                        <p><strong>Miejsce{'\u00A0'}II</strong>: 3000{'\u00A0'}zł na{'\u00A0'}sfinansowanie wycieczki edukacyjnej oraz bilety do{'\u00A0'}miasteczka edukacyjnego minicity.</p>
                      </AwardsListItemDescText >
                    </AwardsListItemDescWrapper >
                  </AwardsListItemInnerWrapper >
                </MainAward>
              </AwardsListItemWrapper >
              <AwardsListItemWrapper position="bottom" xs={12}>
                <AwardsListItemInnerWrapper>
                  <AwardsListItemHeader>
                    <AwardsListItemHeaderImageWrapper>
                      <Img
                        fluid={data.awardImage.childImageSharp.fluid}
                        alt="Wizualizacja/zdjęcie nagrody"
                      />
                    </AwardsListItemHeaderImageWrapper>
                  </AwardsListItemHeader>
                  <AwardsListItemDescWrapper position="bottom">
                    <AwardsListItemDescTitle color="#00975F">
                      Nagroda II{'\u00A0'}stopnia
                      <span>
                        dla{'\u00A0'}klas{'\u00A0'}IV-VI
                      </span>
                    </AwardsListItemDescTitle>
                    <AwardsListItemDescText $color="#000" $fontWeight={200}>
                      Atrakcyjne nagrody dla<br />każdego dziecka oraz nauczyciela
                    </AwardsListItemDescText>
                  </AwardsListItemDescWrapper>
                </AwardsListItemInnerWrapper>
              </AwardsListItemWrapper>
            </Row >
          </AwardsListItemsWrapper >
        </AwardsListOuterRowWrapper >
        <Row>
          <AwardsButtonLinkWrapper col={12}>
            <AwardsButtonLink as={Link} to="/strefa-nauczyciela/konkurs/zgloszenie-pracy" title="Zgłoś pracę">
              Zgłoś pracę
            </AwardsButtonLink>
          </AwardsButtonLinkWrapper>
        </Row>
      </AwardsContainer >
    </AwardsWrapper >
  )
}

export default Awards
