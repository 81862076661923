import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { ButtonLink } from '../elements'

import awardsBGMobile from '../../images/teachers/awards_bg_mobile.svg'

const IntroContestWrapper = styled.div`
  background-image: url(${awardsBGMobile});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  margin-top: 0;
  padding-top: 30px;
  @media (max-width: 991px) {
    background-position: top center;
    padding-bottom: 50px;
  }
`

const IntroContestContainer = styled(Container)``

const IntroContestRowWrapper = styled(Row)``

const IntroContestHeaderWrapper = styled(Col)`
  @media (max-width: 1199px) {
    order: 1;
  }
`

const IntroContestTitle = styled.h1`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    text-align: center;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 38px;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 36px;
  }
  @media (max-width: 575px) {
    font-size: 30px;
    margin-bottom: 20px;
  }
`

const IntroContestSubTitleWrapper = styled.div`
  font-size: 28px;
  font-weight: 400;
  line-height: 1;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 22px;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    font-size: 24px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    font-weight: 700;
  }
  p {
    & > strong {
      display: block;
      color: ${(props) => props.theme.colorGreenLight};
      font-size: 76px;
      font-weight: 700;
      @media (min-width: 1200px) and (max-width: 1399px) {
        font-size: 60px;
      }
      @media (min-width: 576px) and (max-width: 1199px) {
        font-size: 68px;
      }
      @media (max-width: 575px) {
        font-size: 38px;
      }
    }
    br {
      @media (max-width: 575px) {
        display: none;
      }
    }
  }
`

const IntroContestDescWrapper = styled(Col)`
  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    order: 3;
    margin-top: 50px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
`

const IntroContestSubDescWrapper = styled.div`
  line-height: 1;
  @media (min-width: 768px) {
    padding-right: 40%;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 25px;
    @media (min-width: 1200px) and (max-width: 1399px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
    @media (min-width: 576px) and (max-width: 1199px) {
      font-size: 16px;
    }
    @media (max-width: 575px) {
      font-size: 16px;
    }
  }
`

const IntroContestDescLinkWrapper = styled.div`
  text-align: right;
  margin-bottom: 60px;
  @media (max-width: 1199px) {
    order: -1;
    text-align: center;
  }
`

export const IntroContestDescLink = styled(ButtonLink)`
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 25px;
  @media (max-width: 575px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  &:hover,
  &:focus {
    &:before {
      right: 20px;
    }
  }
  &:before {
    right: 25px;
  }
`

const IntroContestImageWrapper = styled(Col)`
  @media (min-width: 1200px) {
    display: flex;
    align-items: flex-start;
  }
  @media (max-width: 1199px) {
    order: 2;
  }
`

const IntroContestImageInnerWrapper = styled.div`
  width: 100%;
`

const IntroContest = (props) => {
  const data = useStaticQuery(graphql`
    query {
      introContestImage: file(
        relativePath: { eq: "teachers/intro_contest.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1002) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <IntroContestWrapper>
      <IntroContestContainer>
        <IntroContestRowWrapper>
          <IntroContestDescWrapper xs={12} xl={5}>
            <IntroContestTitle>Konkurs Misja Kieszonkowe 2024</IntroContestTitle>
            <IntroContestSubTitleWrapper>
              <p>
                Twoi uczniowie są{'\u00A0'}już{'\u00A0'}bogatsi
                <br /> o{'\u00A0'}cenną wiedzę?
              </p>
              <p>
                <strong>
                  Zamieńcie ją
                  <br /> na{'\u00A0'}nagrody!
                </strong>
              </p>
            </IntroContestSubTitleWrapper>
            <IntroContestSubDescWrapper>
              <p>
                W{'\u00A0'}ramach programu edukacyjnego Misja Kieszonkowe 2024 wraz z{'\u00A0'}uczniami
                weźcie udział w{'\u00A0'}konkursie.
              </p>
              <p>
                Prace konkursowe będą oceniane w{'\u00A0'}dwóch kategoriach wiekowych, uczniów klas I-III oraz uczniów klas IV-{'\u00A0'}VI.
              </p>
            </IntroContestSubDescWrapper>
            <IntroContestDescLinkWrapper>
              <IntroContestDescLink as={Link} to="/strefa-nauczyciela/konkurs/zgloszenie-pracy" title="Zgłoś pracę">
                Zgłoś pracę
              </IntroContestDescLink>
            </IntroContestDescLinkWrapper>
          </IntroContestDescWrapper>
          <IntroContestImageWrapper xs={12} xl={7}>
            <IntroContestImageInnerWrapper>
              <Img
                fluid={data.introContestImage.childImageSharp.fluid}
                alt="Konkurs Kieszonkowe"
              />
            </IntroContestImageInnerWrapper>
          </IntroContestImageWrapper>
        </IntroContestRowWrapper>
      </IntroContestContainer>
    </IntroContestWrapper>
  )
}

export default IntroContest
