import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import Img from 'gatsby-image'


import {
  TitleWithLine,
  QuoteLineListWrapper,
  QuoteLineListItem,
  QuoteLineListItemInnerWrapper,
  ButtonLinkTransparent,
} from '../elements'

const GMWWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 30px;
  padding-bottom: 70px;
  @media (min-width: 992px) {
    ${(props) =>
    props.position === 'bottom' &&
    `
      display: none;
    `}
  }
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
`

const GMWContainer = styled(Container)`
  @media (min-width: 1400px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const GMWTitleWrapper = styled(Col)`
  padding-bottom: 60px;
  @media (max-width: 992px) {
    padding-bottom: 10px;
  }
`

const GMWTitle = styled(TitleWithLine)``

const GMWListWrapper = styled(QuoteLineListWrapper)`
  display: flex;

  @media (min-width: 992px) {
    margin-bottom: 70px;
  }
`

const GMWListItem = styled(QuoteLineListItem)`
  display: flex;

  @media (max-width: 991px) {
    ${(props) =>
    props.name === props.active
      ? `
      display: block;
    `
      : `
      display: none;
    `}
  }
`

const GMWListItemInnerWrapper = styled(QuoteLineListItemInnerWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: none;

  @media (max-width: 991px) {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .gray {
    color: #707070;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`


const GMWNoteButtonLinkTransparent = styled(ButtonLinkTransparent)`
  bottom: -40px;
  padding-left: 0;
  position: absolute;
`
const GMWLogo = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 991px) {
      align-self: center;
      position: static;
    }
`



const GMW = (props) => {
  const data = useStaticQuery(graphql`
    query {
      GMWLogoImage: file(relativePath: { eq: "teachers/gmwlogo.png" }) {
        childImageSharp {
          fluid(maxWidth: 520) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  const { position, activeClass } = props
  return (
    <GMWWrapper position={position} id="gmw">
      <GMWContainer>
        {position === 'top' && (
          <Row>
            <GMWTitleWrapper x={12}>
              <GMWTitle color="#91BD24">
                <span>Global Money Week</span>
              </GMWTitle>
            </GMWTitleWrapper>
          </Row>
        )}
        <GMWListWrapper>
          {position === 'top' && (<>
            <GMWListItem
              name="class46"
              active={activeClass}
              xs={12}
              lg={6}
            >
              <GMWListItemInnerWrapper>
                <GMWLogo>
                  <Img
                    fluid={data.GMWLogoImage.childImageSharp.fluid}
                    alt="Global Money Week"
                  />
                </GMWLogo>
              </GMWListItemInnerWrapper>
            </GMWListItem>
            <GMWListItem
              name="class46"
              active={activeClass}
              xs={12}
              lg={6}
            >
              <GMWListItemInnerWrapper>
                <p className="gray">W tym roku konkurs Misja Kieszonkowe włącza się w{'\u00A0'}obchody Global Money Week{'\u00A0'}(GMW), czyli Światowego Tygodnia Pieniądza. Jest to międzynarodowa kampania z{'\u00A0'}zakresu edukacji finansowej, której organizatorem jest Międzynarodowa Sieć ds.{'\u00A0'}Edukacji Finansowej działająca przy Organizacji Współpracy Gospodarczej i{'\u00A0'}Rozwoju - OECD/INFE. W{'\u00A0'}Polsce koordynatorem jest Urząd Komisji Nadzoru Finansowego.</p>
                <p className="gray">Od 2012{'\u00A0'}r. w{'\u00A0'}GMW wzięły udział ponad 53 miliony dzieci i{'\u00A0'}młodzieży ze{'\u00A0'}176 krajów na{'\u00A0'}całym świecie, przy zaangażowaniu ponad 63{'\u00A0'}tysięcy instytucji, w{'\u00A0'}tym instytucji publicznych, tj.{'\u00A0'}banki centralne, instytucje nadzorujące krajowe rynki finansowe, ministerstwa finansów, ministerstwa edukacji, a{'\u00A0'}także szkoły na{'\u00A0'}wszystkich etapach edukacyjnych, uczelnie wyższe, ośrodki badawcze, organizacje społeczeństwa obywatelskiego.</p>
                <p className="gray"><strong>Światowy Tydzień Pieniądza</strong> w{'\u00A0'}tym roku jest celebrowany w{'\u00A0'}dniach 20-26{'\u00A0'}marca. Zapraszamy Was wszystkich, aby włączyć swoje działania w{'\u00A0'}obchody Global Money Week, niezależnie od{'\u00A0'}tego, kiedy będziecie świętować. </p>
                <p className="gray">Zachęcamy do opisania w{'\u00A0'}zgłoszeniu konkursowym do Misji Kieszonkowe 2023 jak Wasze działania wpisują się w{'\u00A0'}tematykę przewodnią GMW - Zaplanuj swoje finanse i{'\u00A0'}swoją przyszłość.<br />
                  <GMWNoteButtonLinkTransparent
                    as={Link}
                    to="https://globalmoneyweek.org/"
                    title="Tu znajdziesz więcej informacji o Global Money Week"
                    target="_blank"
                  >
                    Tu znajdziesz więcej informacji o Global Money Week
                  </GMWNoteButtonLinkTransparent></p>
              </GMWListItemInnerWrapper>
            </GMWListItem>
          </>)}
        </GMWListWrapper>
      </GMWContainer>
    </GMWWrapper>
  )
}

export default GMW
