import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import React, { useState } from 'react'
import { Container, Row, Col } from 'reactstrap'
import Slick from 'react-slick'
import styled from 'styled-components'

import { ButtonLink, TitleWithLine } from '../elements'


const Content = styled.div`
  background-color: rgba(201, 201, 201, .1);
  backdrop-filter: blur(30px);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-bottom: 25px;
  padding-top: 15px;
  right: 0;
  text-align: center;
  transform: translateY(-30px);

  p {
    color: ${props => props.theme.colorGreenLight};
    font-size: 23px;
    font-weight: 700;
  }
`


const OuterWrapper = styled.div`
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 92.5%;
    right: 0;
    bottom: 0;
    filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(20px);
    background-color: rgba(255, 255, 255, 0.6);
    pointer-events: none;
    width: 7.5%;
    z-index: 10;
  }
`


const Wrapper = styled.div`
  margin: 0 auto;
  width: 85%;
`


const Slide = styled.div`
  font-size: 0;
  max-width: 398px;
  overflow: hidden;
  position: relative;

  & > div {
    border-radius: 10px;
    margin: 0 7.5px;
    overflow: hidden;
    position: relative;
  }
`


const Slider = styled(Slick)`

  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    padding-top: 30px;
    padding-bottom: 10px;
    @media (min-width: 576px) and (max-width: 1199px) {
      padding-bottom: 15px;
    }
    &.slick-center {
      &.slick-current {
      }
    }
  }

  .slick-dots {
    bottom: -40px;
    z-index: 10;
    li {
      width: 25px;
      margin-left: 0;
      margin-right: 0;
      &.slick-active {
        button {
          &:before {
            background-color: ${(props) => props.theme.colorGreenLight};
          }
        }
      }
      button {
        width: 25px;
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          border: 1px solid ${(props) => props.theme.colorGreenLight};
          border-radius: 50%;
        }
      }
    }
  }
`


const SliderWrapper = styled.div`
  margin: 0 -15px;
  position: relative;
`


const TitleWrapper = styled(Col)`

  @media (max-width: 991px) {
    display: none;
  }
`


export default () => {
  const [activeSlide, setActiveSlide] = useState(0)
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    centerPadding: '14.5%',
    autoplay: false,
    autoplaySpeed: 15000,
    responsive: [
      {
        breakpoint: 1679,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      Photo1: file(
        relativePath: { eq: "teachers/guides/wystawa.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Photo2: file(
        relativePath: { eq: "teachers/guides/turniej-klasowy.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Photo3: file(
        relativePath: { eq: "teachers/guides/kiermasz-szkolny.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Photo4: file(
        relativePath: { eq: "teachers/guides/spotkanie-z-ekspertem.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      Photo5: file(
        relativePath: { eq: "teachers/guides/konkurs-plastyczny.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  const slides = [
    { title: 'Wystawa', url: '/docs/MK2024-Misja_Kieszonkowe_-_wystawa.pdf', image: data.Photo1 },
    { title: 'Turniej klasowy', url: '/docs/MK2024-Misja_Kieszonkowe_-_turniej_klasowy.pdf', image: data.Photo2 },
    { title: 'Kiermasz szkolny', url: '/docs/MK2024-Misja_Kieszonkowe_-_kiermasz_szkolny.pdf', image: data.Photo3 },
    { title: 'Spotkanie z ekspertem', url: '/docs/MK2024-Misja_Kieszonkowe_-_spotkanie_z_ekspertem.pdf', image: data.Photo4 },
    { title: 'Konkurs plastyczny', url: '/docs/MK2024-Misja_Kieszonkowe_-_konkurs_plastyczny.pdf', image: data.Photo5 },
  ];

  return (
    <OuterWrapper id="guides">
      <Wrapper>
        <TitleWithLine color="#91BD24">
          <span>Przewodniki</span>
        </TitleWithLine>
        <SliderWrapper>
          <Slider {...sliderSettings}>
            {slides.map(slide => <Slide>
              <div>
                <Img fluid={slide.image.childImageSharp.fluid} alt={slide.title} />
                <Content>
                  <p>{slide.title}</p>
                  <ButtonLink href={withPrefix(slide.url)} target="_blank" rel="noopener noreferrer">Pobierz przewodnik</ButtonLink>
                </Content>
              </div>
            </Slide>)}
          </Slider>
        </SliderWrapper>
      </Wrapper>
    </OuterWrapper>
  )
}