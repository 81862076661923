import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Collapse } from 'reactstrap'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import IntroContest from '../../../components/teachers/intro-contest'
import TaskContests from '../../../components/teachers/task-contests'
import GMW from '../../../components/teachers/gmw'
import Awards from '../../../components/teachers/awards'
import Guides from '../../../components/teachers/guides'
import ScheduleContest from '../../../components/teachers/schedule-contest'
import Contact from '../../../components/teachers/contact'
import TopScroll from '../../../components/parent/top-scroll'
import Partners from '../../../components/teachers/partners'
import Patrons from '../../../components/teachers/patrons'
import Winners from '../../../components/teachers/winners'
import LinksAnchors from '../../../components/elements/links-anchors'

import arrowGreenIcon from '../../../images/arrow_select_green.svg'

const IndexPageWrapper = styled.div`
  overflow: hidden;
`

const IndexPageInnerWrapper = styled.div``

const IndexPageTopScroll = styled(TopScroll)`
  @media (min-width: 768px) {
    display: none;
  }
`

const IndexPageCollapseSwitcherWrapper = styled.div`
  padding-top: 50px;
  @media (min-width: 992px) {
    display: none !important;
  }
`

const IndexPageCollapseWrapper = styled(Collapse)`
  @media (min-width: 992px) {
    display: block !important;
  }
`

const IndexPageCollapseInnerWrapper = styled.div``

const IndexPageCollapseTitle = styled.p`
  position: relative;
  font-size: 20px;
  font-weight: 400;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 8px;
  margin-bottom: 0;
  border-bottom: 1px solid ${(props) => props.theme.colorGreenLight};
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
  @media (max-width: 575px) {
    font-size: 18px;
  }
  &:last-of-type {
    margin-bottom: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    display: block;
    width: 14px;
    height: 14px;
    background-image: url(${arrowGreenIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: translateY(-50%) rotate(0deg);
    transition: all 0.2s ease;
    ${(props) =>
    props.name === props.active &&
    props.show &&
    `
      transform: translateY(-50%) rotate(180deg);
    `}
  }
`

const IndexPage = ({ location }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false)
  const [activeClass, setActiveClass] = useState('')

  const toggle = (nameClass) => {
    setActiveClass(nameClass)
    setIsCollapseOpen(!isCollapseOpen)
  }

  return (
    <>
      <IndexPageWrapper id="top">
        <IndexPageInnerWrapper>
          <SEO title="Strefa nauczyciela" />
          <Layout location={location}>
            <IntroContest />
            <LinksAnchors
              list={[
                /*{
                  id: 'winners',
                  name: 'Wyniki konkursu',
                  offset: -150,
                },*/
                {
                  id: 'task',
                  name: 'Zadanie konkursowe',
                  offset: -150,
                },
                {
                  id: 'guides',
                  name: 'Przewodniki',
                  offset: -150,
                },
                /*{
                  id: 'gmw',
                  name: 'Global Money Week',
                  offset: -150,
                },*/
                {
                  id: 'prizes',
                  name: 'Nagrody',
                  offset: -150,
                },
                {
                  id: 'schedule',
                  name: 'Kalendarium konkursu',
                  offset: -150,
                },
              ]}
              settings={{
                mobileDropdowns: false,
              }}
            />
            {/*<Winners />*/}
            <IndexPageCollapseSwitcherWrapper>
              <Container>
                <Row>
                  <Col xs={12}>
                    <IndexPageCollapseTitle
                      name="class3"
                      show={isCollapseOpen}
                      active={activeClass}
                      onClick={() => toggle('class3')}
                    >
                      Klasy I-III
                    </IndexPageCollapseTitle>
                    <IndexPageCollapseTitle
                      name="class46"
                      show={isCollapseOpen}
                      active={activeClass}
                      onClick={() => toggle('class46')}
                    >
                      Klasy IV-VI
                    </IndexPageCollapseTitle>
                  </Col>
                </Row>
              </Container>
            </IndexPageCollapseSwitcherWrapper>
            <IndexPageCollapseWrapper isOpen={isCollapseOpen}>
              <IndexPageCollapseInnerWrapper>
                <TaskContests activeClass={activeClass} position="top" />
                <Guides />
                {/*<GMW activeClass={activeClass} position="top" />*/}
                <Awards
                  activeClass={activeClass}
                  isCollapseOpen={isCollapseOpen}
                />
              </IndexPageCollapseInnerWrapper>
            </IndexPageCollapseWrapper>
            <TaskContests activeClass={activeClass} position="bottom" />
            <ScheduleContest />
            <Partners />
            {/*<Patrons />*/}
            <Contact />
            <IndexPageTopScroll />
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
