import React, { useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'
import Slider from 'react-slick'

import { TitleWithLine } from '../elements'

const PartnersWrapper = styled.div`
  padding-top: 40px;
`

const PartnersTopWrapper = styled.div`
  padding-bottom: 10px;
`

const PartnersTopContainer = styled(Container)``

const PartnersTitle = styled(TitleWithLine)``

const PartnersBottomWrapper = styled.div`
  background-color: #f7f7f7;
  color: ${(props) => props.theme.colorGray};
`

const PartnersBottomContainer = styled.div`
  .slick-slider {
    width: calc(100% - 350px);

    @media (max-width: 1399px) {
      width: calc(100% - 250px);
    }

    .slick-list {
      overflow: visible !important;
    }

    .slick-slide {
      & > div {
        text-align: center;
      }
    }

    .slick-track {
      align-items: center;
      column-gap: 30px;
      display: flex;
      flex-direction: row;
      margin: 30px 0;
    }
  }
`

const PartnersBottomRowWrapper = styled(Row)`
  justify-content: center;
`

const Item = styled.a.attrs({
  target: `_blank`,
})`
  align-items: center;
  display: flex;
  height: 100%;
  max-width: 175px;
  margin: 0 auto;

  span {
    color: #000;
    display: block;
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 20px;
    text-decoration: none;
  }
`

const Partners = (props) => {
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: `70px`,
        },
      },
    ],
  }

  const data = useStaticQuery(graphql`
    query {
      p1: file(relativePath: { eq: "patrons/DobreMiasto.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p2: file(relativePath: { eq: "patrons/Biskupiec.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p3: file(relativePath: { eq: "patrons/Chelmza.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p4: file(relativePath: { eq: "patrons/DrawskoPomorskie.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p5: file(relativePath: { eq: "patrons/Gliwice.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "#f7f7f7") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p6: file(relativePath: { eq: "patrons/Hajnowka.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p7: file(relativePath: { eq: "patrons/Krotoszyn.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "#f7f7f7") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p8: file(relativePath: { eq: "patrons/Lidzbark.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p9: file(relativePath: { eq: "patrons/Pultusk.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p10: file(relativePath: { eq: "patrons/Wloclawek.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      p11: file(relativePath: { eq: "patrons/Wlodawa.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 100, cropFocus: CENTER, fit: CONTAIN, background: "rgba(255, 255, 255, 0)") {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const items = [
    { image: `p1`, alt: `Burmistrz Dobrego Miasta`, url: null },
    { image: `p2`, alt: `Burmistrz Miasta Biskupiec`, url: null },
    { image: `p3`, alt: `Burmistrz Miasta Chełmża`, url: null },
    { image: `p4`, alt: `Burmistrz Drawska Pomorskiego`, url: null },
    { image: `p5`, alt: `Prezydent Miasta Gliwice`, url: null },
    { image: `p6`, alt: `Wójt Gminy Hajnówka`, url: null },
    { image: `p7`, alt: `Burmistrz Krotoszyna`, url: null },
    { image: `p8`, alt: `Burmistrz Lidzbarka`, url: null },
    { image: `p9`, alt: `Burmistrz Miasta Pułtusk`, url: null },
    { image: `p10`, alt: `Prezydent Miasta Włocławek`, url: null },
    { image: `p11`, alt: `Burmistrz Włodawy`, url: null },
  ]

  const SliderRef = useRef()
  useEffect(() => {
    SliderRef.current.slickGoTo(1)
  }, [])

  return (
    <PartnersWrapper id="partners">
      <PartnersTopWrapper>
        <PartnersTopContainer>
          <Row>
            <Col xs={12}>
              <PartnersTitle color="#91BD24">
                <span>Patronaty</span>
              </PartnersTitle>
            </Col>
          </Row>
        </PartnersTopContainer>
      </PartnersTopWrapper>
      <PartnersBottomWrapper>
        <PartnersBottomContainer>
          <PartnersBottomRowWrapper>
            <Slider {...sliderSettings} ref={SliderRef}>
              {items.map((item) => (
                <Item href={item.url} as={item.url ? `a` : `div`}>
                  <Img
                    fluid={data[item.image].childImageSharp.fluid}
                    alt={item.alt}
                  />
                  <span>{item.alt}</span>
                </Item>
              ))}
            </Slider>
          </PartnersBottomRowWrapper>
        </PartnersBottomContainer>
      </PartnersBottomWrapper>
    </PartnersWrapper>
  )
}

export default Partners
