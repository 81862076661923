import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import {
  TitleWithLine,
  QuoteLineListWrapper,
  QuoteLineListItem,
  QuoteLineListItemInnerWrapper,
  QuoteLineListTitle,
  ButtonLink,
  ButtonLinkTransparent,
} from '../elements'

const TaskContestsWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.2;
  padding-top: 30px;
  padding-bottom: 70px;
  @media (min-width: 992px) {
    background-color: rgba(201, 201, 201, 0.2);
    ${(props) =>
    props.position === 'bottom' &&
    `
      display: none;
    `}
  }
  @media (max-width: 991px) {
    padding-bottom: 0;
  }
`

const TaskContestsContainer = styled(Container)`
  @media (min-width: 1400px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (min-width: 992px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const TaskContestsTitleWrapper = styled(Col)`
  padding-bottom: 60px;
  @media (max-width: 991px) {
    display: none;
  }
`

const TaskContestsTitle = styled(TitleWithLine)``

const TaskContestsListWrapper = styled(QuoteLineListWrapper)`
  display: flex;

  @media (min-width: 992px) {
    margin-bottom: 70px;
  }
`

const TaskContestsListItem = styled(QuoteLineListItem)`
  display: flex;

  @media (max-width: 991px) {
    ${(props) =>
    props.name === props.active
      ? `
      display: block;
    `
      : `
      display: none;
    `}
  }
`

const TaskContestsListItemInnerWrapper = styled(QuoteLineListItemInnerWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 991px) {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 30px;
  }

  .gray {
    color: #707070;
  }

  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

const TaskContestsListTitle = styled(QuoteLineListTitle)`
  @media (max-width: 991px) {
    display: none;
  }
`

const TaskContestsNoteRowWrapper = styled(Row)`
  @media (max-width: 991px) {
    padding-bottom: 60px;
    ${(props) =>
    props.position === 'bottom'
      ? `
      display: flex;
    `
      : `
      display: none;
    `}
  }
  @media (min-width: 576px) and (max-width: 991px) {
    padding-left: 10%;
    padding-right: 10%;
  }
`

const TaskContestsNoteWrapper = styled(Col)`
  font-size: 18px;
  font-weight: 400;
  @media (max-width: 575px) {
    font-size: 15px;
  }
  &:first-child {
    @media (min-width: 992px) {
      text-align: center;
      padding-left: 15%;
    }
  }
  &:last-child {
    @media (min-width: 992px) {
      padding-left: 60px;
    }
  }
  @media (max-width: 991px) {
    text-align: center;
  }
`

const TaskContestsNoteButtonLinkTransparent = styled(ButtonLinkTransparent)`
  bottom: -40px;
  padding-left: 0;
  position: absolute;
`

const TaskContestsNoteButtonLink = styled(ButtonLink)``

const TaskContests = (props) => {
  const { position, activeClass } = props
  return (
    <TaskContestsWrapper position={position} id="task">
      <TaskContestsContainer>
        {position === 'top' && (
          <Row>
            <TaskContestsTitleWrapper x={12}>
              <TaskContestsTitle color="#91BD24">
                <span>Zadanie konkursowe</span>
              </TaskContestsTitle>
            </TaskContestsTitleWrapper>
          </Row>
        )}
        <TaskContestsListWrapper>
          {position === 'top' && (<>
            <TaskContestsListItem
              name="class46"
              active={activeClass}
              xs={12}
            >
              <TaskContestsListItemInnerWrapper>
                <h3>Informacje</h3>
              </TaskContestsListItemInnerWrapper>
            </TaskContestsListItem>
            <TaskContestsListItem
              name="class46"
              active={activeClass}
              xs={12}
              lg={6}
            >
              <TaskContestsListItemInnerWrapper>
                <p className="gray">Po{'\u00A0'}przeprowadzeniu zajęć w{'\u00A0'}ramach programu edukacyjnego Misja Kieszonkowe 2024, wykorzystajcie zdobytą wiedzę i{'\u00A0'}całą klasą zorganizujcie wydarzenie na{'\u00A0'}dowolny temat z{'\u00A0'}zakresu finansów. Aktywności jakie możecie podjąć to{'\u00A0'}między innymi: <strong>wystawa, spotkanie z{'\u00A0'}ekspertami, turniej między-klasowy lub konkurs plastyczny</strong>.</p>
                <p><strong>Zanim przejdziecie do realizacji zadania konkursowego zapoznajcie się z{'\u00A0'}przewodnikami, z{'\u00A0'}których dowiecie się jak zorganizować wydarzenie w{'\u00A0'}ramach Misji Kieszonkowe.</strong></p>
              </TaskContestsListItemInnerWrapper>
            </TaskContestsListItem>
            <TaskContestsListItem
              name="class46"
              active={activeClass}
              xs={12}
              lg={6}
            >
              <TaskContestsListItemInnerWrapper>
                <p className="gray">Zgłoszenie konkursowe powinno zawierać założenia, opis atrakcji zrealizowanych w{'\u00A0'}trakcie wydarzenia oraz jego przebieg wraz z{'\u00A0'}dołączoną fotorelacją. Zgłoszenie prześlijcie w{'\u00A0'}formacie pptx/pdf lub skanu plakatu (jpg, png) z{'\u00A0'}wklejonymi zdjęciami do{'\u00A0'}31 maja 2024 r.</p>
                <p>
                  <strong>Chcecie wziąć udział w{'\u00A0'}konkursie? Pamiętajcie, aby było to{'\u00A0'}możliwe, musicie w pierwszej kolejności być uczestnikami programu Misja Kieszonkowe 2024. Jeżeli jeszcze nimi nie jesteście</strong><br />
                  <TaskContestsNoteButtonLinkTransparent
                    as={Link}
                    to="/strefa-nauczyciela/konkurs/zgloszenie-klasy"
                    title="Zgłoś się do programu"
                  >
                    Zgłoście się do programu
                  </TaskContestsNoteButtonLinkTransparent>
                </p>
              </TaskContestsListItemInnerWrapper>
            </TaskContestsListItem>
          </>)}
        </TaskContestsListWrapper>
      </TaskContestsContainer>
    </TaskContestsWrapper>
  )
}

export default TaskContests
