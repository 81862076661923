import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine } from '../elements'

import PartnerMCImage from '../../images/teachers/partner_mc.svg'

const PartnersWrapper = styled.div`
  padding-top: 40px;
`

const PartnersTopWrapper = styled.div`
  padding-bottom: 10px;
`

const PartnersTopContainer = styled(Container)``

const PartnersTitle = styled(TitleWithLine)``

const PartnersBottomWrapper = styled.div`
  h3 {
    font-weight: bold;

    span {
      color: #019662;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    & + h3 {
      margin-top: 45px;
    }

    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background-color: rgba(201, 201, 201, .1);
          }
        }

        &:hover {
          td {
            background-color: rgba(201,201,201,0.2);
          }
        }

        td {
          padding: 5px;

          &:nth-child(3) {
            text-align: center;
          }
        }
      }
    }

    thead {
      th {
        font-weight: bold;

        &:nth-child(3) {
          text-align: center;
          width: 75px;
        }
      }
    }
  }
`

const PartnersBottomContainer = styled(Container)`
  padding-top: 40px;
  padding-bottom: 80px;
  @media (min-width: 1200px) {
    padding-left: 8%;
    padding-right: 8%;
  }
  @media (max-width: 575px) {
    padding-bottom: 40px;
    margin-left: 5%;
    margin-right: 5%;
  }
`

const PartnersBottomRowWrapper = styled(Row)`
  justify-content: center;
`

const PartnersCardItem = styled(Col)`
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-style: italic;
  @media (min-width: 1200px) {
    flex: 0 0 20%;
    max-width: 20%;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
  @media (max-width: 1199px) {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 40px;
  }
  @media (max-width: 575px) {
    font-size: 12px;
    padding-bottom: 20px;
  }
`

const PartnersCardImageWrapper = styled.div`
  overflow: hidden;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 50%;

  img {
    height: auto;
    max-width: 100%;
  }
`

const PartnersCardTitle = styled.p`
  text-align: center;
  margin-top: 15px;
`

const Winners = (props) => {
  return (
    <PartnersWrapper id="winners">
      <PartnersTopWrapper>
        <PartnersTopContainer>
          <Row>
            <Col xs={12}>
              <PartnersTitle color="#91BD24">
                <span>Poznaj zwycięzców konkursu Misja Kieszonkowe 2023</span>
              </PartnersTitle>
            </Col>
          </Row>
        </PartnersTopContainer>
      </PartnersTopWrapper>
      <PartnersBottomWrapper>
        <PartnersBottomContainer>
          <PartnersBottomRowWrapper>
            <h3>NAGRODA I STOPNIA <span>KLASY I-III</span></h3>
            <table>
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Klasa</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>SZKOŁA PODSTAWOWA NR 16 IM. WANDY SZUMAN W TORUNIU</td>
                  <td>1C</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>SZKOŁA PODSTAWOWA NR 1 W KONSTANCINIE-JEZIORNA</td>
                  <td>1A</td>
                </tr>
              </tbody>
            </table>

            <h3>NAGRODA II STOPNIA <span>KLASY I-III</span></h3>
            <table>
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Klasa</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>SZKOŁA PODSTAWOWA NR 2 Z ODDZIAŁAMI DWUJĘZYCZNYMI I SPORTOWYMI IM. SZARYCH SZEREGÓW W LIDZBARKU</td>
                  <td>1B</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>SZKOŁA PODSTAWOWA NR 2 IM.  KSIĘDZA STANISŁAWA STASZICA W CHEŁMNIE</td>
                  <td>3A</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>SZKOŁA PODSTAWOWA NR 1 IM. ZOFII URBANOWSKIEJ W KONINIE</td>
                  <td>3B</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>SZKOŁA PODSTAWOWA NR 1 IM. MIKOŁAJA KOPERNIKA W MRĄGOWIE</td>
                  <td>3F</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>SZKOŁA PODSTAWOWA IM. PROF. ALEKSANDRA KOSIBY W LIBUSZY</td>
                  <td>1A</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>SZKOŁA PODSTAWOWA IM. 16 PUŁKÓW UŁANÓW WIELKOPOLSKICH W GRUPIE</td>
                  <td>1A</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>PUBLICZNA SZKOŁA PODSTAWOWA W MICHAŁOWIE</td>
                  <td>3A</td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>SZKOŁA PODSTAWOWA IM. MJR. WŁ. RAGINISA W PIĄTNICY</td>
                  <td>3A</td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>SZKOŁA PODSTAWOWA NR 4 W RYDUŁTOWACH</td>
                  <td>1A</td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td>SZKOŁA PODSTAWOWA NR 4 IM. PROF. ADAMA WODZICZKI W LUBONIU</td>
                  <td>3B</td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>SZKOŁA PODSTAWOWA NR 4 IM. ADAMA MICKIEWICZA W BIELSKU PODLASKIM</td>
                  <td>2D</td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td>SZKOŁA PODSTAWOWA IM. TADEUSZA PEZAŁY W PURDZIE</td>
                  <td>1A</td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td>SZKOŁA PODSTAWOWA NR 9 SPOŁECZNEGO TOWARZYSTWA OŚWIATOWEGO W WARSZAWIE</td>
                  <td>1B</td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td>SZKOŁA PODSTAWOWA IM. JANA PAWŁA II W BIESALU</td>
                  <td>1A</td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td>SZKOŁA PODSTAWOWA IM. GEN.W. SIKORSKIEGO W TUSZOWIE NARODOWYM Z FILIĄ W GROCHOWEM</td>
                  <td>3A</td>
                </tr>
                <tr>
                  <td>16.</td>
                  <td>SZKOŁA PODSTAWOWA W AUGUSTOWIE</td>
                  <td>2B</td>
                </tr>
                <tr>
                  <td>17.</td>
                  <td>PUBLICZNA SZKOŁA PODSTAWOWA NR 2 IM. JANUSZA KORCZAKA W KLUCZBORKU</td>
                  <td>3D</td>
                </tr>
                <tr>
                  <td>18.</td>
                  <td>SZKOŁA PODSTAWOWA NR 33 IM. MARII KOWNACKIEJ W CZĘSTOCHOWIE</td>
                  <td>3A</td>
                </tr>
              </tbody>
            </table>

            <h3>NAGRODA I STOPNIA <span>KLASY IV-VI</span></h3>
            <table>
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Klasa</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>SZKOŁA PODSTAWOWA IM. FLAGII POLSKIEJ W KOBUŁTACH</td>
                  <td>6A</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>SZKOŁA PODSTAWOWA W ZARĘBIE</td>
                  <td>4A</td>
                </tr>
              </tbody>
            </table>

            <h3>NAGRODA II STOPNIA <span>KLASY IV-VI</span></h3>
            <table>
              <thead>
                <tr>
                  <th>Lp.</th>
                  <th>Nazwa</th>
                  <th>Klasa</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1.</td>
                  <td>SZKOŁA PODSTAWOWA NR 1 IM. STANISŁAWA JACHOWICZA W BOCHNI</td>
                  <td>6C</td>
                </tr>
                <tr>
                  <td>2.</td>
                  <td>SZKOŁA PODSTAWOWA IM. BOJOWNIKÓW O WOLNOŚĆ I DEMOKRACJĘW KRZYŻU WIELKOPOLSKIM</td>
                  <td>6B</td>
                </tr>
                <tr>
                  <td>3.</td>
                  <td>SZKOŁA PODSTAWOWA IM. K. TRYBUSA W WOJASZÓWCE</td>
                  <td>5A</td>
                </tr>
                <tr>
                  <td>4.</td>
                  <td>SZKOŁA PODSTAWOWA IM. WYDARZEŃ WRZEŚNIA W CZARTOWCZYKU</td>
                  <td>6A</td>
                </tr>
                <tr>
                  <td>5.</td>
                  <td>SZKOŁA PODSTAWOWA IM. POLSKICH NOBLISTÓW Z ODZIAŁAMI PRZEDSZKOLNYMI W KŁOCZEWIE</td>
                  <td>4B</td>
                </tr>
                <tr>
                  <td>6.</td>
                  <td>SZKOŁA PODSTAWOWA W KOZUBOWIE</td>
                  <td>6A</td>
                </tr>
                <tr>
                  <td>7.</td>
                  <td>SZKOŁA PODSTAWOWA NR 11 IM. GEN DEZYDEREGO CHŁAPOWSKIEGO W POZNANIU</td>
                  <td>5B</td>
                </tr>
                <tr>
                  <td>8.</td>
                  <td>SZKOŁA PODSTAWOWA NR 9 IM. SPOŁECZNEGO TOWARZYSTWA OŚWIATOWEGO W WARSZAWIE</td>
                  <td>4A</td>
                </tr>
                <tr>
                  <td>9.</td>
                  <td>SZKOŁA PODSTAWOWA IM. BATALIONÓW CHŁOPSKICH W WÓLCE WIEPRZECKIEJ</td>
                  <td>5A</td>
                </tr>
                <tr>
                  <td>10.</td>
                  <td>SZKOŁA PODSTAWOWA IM. MARII KONOPNICKIEJ W NOWEJ WSI</td>
                  <td>4A</td>
                </tr>
                <tr>
                  <td>11.</td>
                  <td>SZKOŁA PODSTAWOWA NR 35 W CZĘSTOCHOWIE</td>
                  <td>4A</td>
                </tr>
                <tr>
                  <td>12.</td>
                  <td>SZKOŁA PODSTAWOWA NR 35 W CZĘSTOCHOWIE</td>
                  <td>4B</td>
                </tr>
                <tr>
                  <td>13.</td>
                  <td>SZKOŁA PODSTAWOWA NR 1 IM. NOBLISTÓW POLSKICH W DZIERŻONIOWIE</td>
                  <td>5C</td>
                </tr>
                <tr>
                  <td>14.</td>
                  <td>SZKOŁA PODSTAWOWA IM. BOLESŁAWA CHROBREGO W ZAWIDOWIE</td>
                  <td>5A</td>
                </tr>
                <tr>
                  <td>15.</td>
                  <td>SZKOŁA PODSTAWOWA NR 23 IM. JANA PAWŁA II W RZESZOWIE</td>
                  <td>4C</td>
                </tr>
                <tr>
                  <td>16.</td>
                  <td>SZKOŁA PODSTAWOWA NR 4 IM. KPT. JÓZEFA REJDYCHA W GRODZISKU WIELKOPOLSKIM</td>
                  <td>4B</td>
                </tr>
                <tr>
                  <td>17.</td>
                  <td>SZKOŁA PODSTAWOWA IM. MIŁOŚNIKÓW PRZYRODY W LASKOWNICY</td>
                  <td>5A</td>
                </tr>
                <tr>
                  <td>18.</td>
                  <td>SZKOŁA PODSTAWOWA IM. JANA PAWŁA II W MICHAŁOWICACH</td>
                  <td>6C</td>
                </tr>
              </tbody>
            </table>
          </PartnersBottomRowWrapper>
        </PartnersBottomContainer>
      </PartnersBottomWrapper>
    </PartnersWrapper>
  )
}

export default Winners
