import React from 'react'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { Row, Col } from 'reactstrap'
import { ButtonLink } from '../elements'
import {
  ScheduleProgramWrapper,
  ScheduleProgramContainer,
  ScheduleProgramTitle,
  ScheduleProgramProgressBarWrapper,
  ScheduleProgramProgressBar,
  ScheduleProgramProgressBarImageWrapper,
  ScheduleProgramProgressBarTitleBox,
  ScheduleProgramProgressBarDate,
} from './schedule-program'

import contestBarBG from '../../images/teachers/contest_bar_bg.1.svg'

const ScheduleContestWrapper = styled(ScheduleProgramWrapper)``

const ScheduleContestContainer = styled(ScheduleProgramContainer)``

const ScheduleContestStagesWrapper = styled(Row)`
  padding-top: 30px;
  padding-bottom: 60px;
  @media (max-width: 575px) {
    padding-top: 10px;
  }
`

const ScheduleContestStageItem = styled(({ stage, ...rest }) => (
  <Col {...rest} />
))`
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-left: 90px;
  padding-bottom: 20px;
  @media (max-width: 1199px) {
    font-weight: 700;
    margin-bottom: 20px;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    margin-bottom: 10px;
  }
  &:before {
    content: '${(props) => props.stage}';
    position: absolute;
    top: 50%;
    left: 15px;
    display: block;
    width: 56px;
    height: 56px;
    color: #c9c9c9;
    font-family: ${(props) => props.theme.fontBNPPSansCondensed};
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    line-height: 56px;
    border: 2px solid ${(props) => props.theme.colorGreenBright};
    border-radius: 50%;
    transform: translateY(-50%);
  }
  p {
    line-height: 1.1;
    margin-bottom: 0;
  }
`

const ScheduleContestTitle = styled(ScheduleProgramTitle)``

const ScheduleContestSubTitle = styled.p`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  padding-top: 30px;
  padding-left: 30%;
  padding-right: 30%;
  padding-bottom: 50px;
  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: 575px) {
    font-size: 16px;
    font-weight: 700;
    padding-left: 2.5%;
    padding-right: 2.5%;
    padding-bottom: 0;
  }
`

const ScheduleContestProgressBarWrapper = styled(
  ScheduleProgramProgressBarWrapper
)`
  margin-bottom: 30px;
`

const ScheduleContestProgressBar = styled(ScheduleProgramProgressBar)`
  &.scheduleContest {
    @media (min-width: 992px) {
      background-image: url(${contestBarBG});
    }
  }
`

const ScheduleContestProgressBarImageWrapper = styled(
  ScheduleProgramProgressBarImageWrapper
)``

const ScheduleContestProgressBarTitleBox = styled(
  ScheduleProgramProgressBarTitleBox
)`
  &.scheduleContest {
    &.box1 {
      left: 9%;
      @media (max-width: 991px) {
        left: 8%;
        bottom: 106%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.box2 {
      left: 30.5%;
      @media (max-width: 991px) {
        left: 60%;
        bottom: 106%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.box3 {
      left: 54%;
      @media (max-width: 991px) {
        left: 58%;
        bottom: 18%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.box4 {
      left: 77%;
      @media (max-width: 991px) {
        left: 7%;
        bottom: 18%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
  }
`

const ScheduleContestProgressBarDate = styled(ScheduleProgramProgressBarDate)`
  &.scheduleContest {
    &.date1 {
      left: 11%;
      @media (min-width: 992px) and (max-width: 1199px) {
        left: 4%;
      }
      @media (max-width: 991px) {
        left: 8%;
        bottom: 72%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.date2 {
      left: 32.8%;
      @media (min-width: 992px) and (max-width: 1199px) {
        left: 23%;
      }
      @media (max-width: 991px) {
        left: 60%;
        bottom: 72%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.date3 {
      left: 56.2%;
      @media (min-width: 992px) and (max-width: 1199px) {
        left: 43%;
      }
      @media (max-width: 991px) {
        left: 58%;
        bottom: -12%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
    &.date4 {
      left: 79.5%;
      @media (min-width: 992px) and (max-width: 1199px) {
        left: 63.5%;
      }
      @media (max-width: 991px) {
        left: 7%;
        bottom: -12%;
      }
      @media (min-width: 768px) and (max-width: 991px) {
      }
      @media (min-width: 576px) and (max-width: 767px) {
      }
      @media (max-width: 575px) {
      }
    }
  }
`
const ScheduleContestWinnersButtonLinkWrapper = styled(Col)`
  text-align: center;
  padding-top: 30px;
`
const ScheduleContestWinnersButtonLink = styled(ButtonLink)``

const ScheduleContest = (props) => {
  const data = useStaticQuery(graphql`
    query {
      programBarMobile: file(
        relativePath: { eq: "teachers/program_bar_mobile.1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <ScheduleContestWrapper id="schedule">
      <ScheduleContestContainer>
        <Row>
          <Col>
            <ScheduleContestTitle color="#91BD24" id="timetable">
              <span>Kalendarium konkursu</span>
            </ScheduleContestTitle>
            <ScheduleContestSubTitle>
              Zanim weźmiesz udział w{'\u00A0'}konkursie, zarejestruj się w{'\u00A0'}programie Misja Kieszonkowe i{'\u00A0'}przeprowadź zajęcia. Pamiętaj też, by{'\u00A0'}przed wykonaniem zadania konkursowego zapoznać się z{'\u00A0'}naszymi przewodnikami, które ułatwią Ci{'\u00A0'}jego realizację.
            </ScheduleContestSubTitle>
          </Col>
        </Row>
        <Row>
          <ScheduleContestStagesWrapper>
            <ScheduleContestStageItem stage={1} xs={12} sm={6} xl={3}>
              <p>Wypełnij wszystkie pola formularza i{'\u00A0'}zaznacz zgody, które wcześniej uzyskałeś</p>
            </ScheduleContestStageItem>
            <ScheduleContestStageItem stage={2} xs={12} sm={6} xl={3}>
              <p>Dodaj pracę w{'\u00A0'}odpowiednim formacie</p>
            </ScheduleContestStageItem>
            <ScheduleContestStageItem stage={3} xs={12} sm={6} xl={3}>
              <p>Prześlij zgłoszenie</p>
            </ScheduleContestStageItem>
            <ScheduleContestStageItem stage={4} xs={12} sm={6} xl={3}>
              <p>
                Po{'\u00A0'}pomyślnej weryfikacji - czekaj na{'\u00A0'}wyniki
                konkursu!
              </p>
            </ScheduleContestStageItem>
          </ScheduleContestStagesWrapper>
          <ScheduleContestProgressBarWrapper xs={12}>
            <ScheduleContestProgressBar className="scheduleContest">
              <ScheduleContestProgressBarImageWrapper>
                <Img
                  fluid={data.programBarMobile.childImageSharp.fluid}
                  alt="Kalendarium konkursu"
                />
              </ScheduleContestProgressBarImageWrapper>
              <ScheduleContestProgressBarTitleBox className="box1 scheduleContest active">
                <span>Start konkursu</span>
              </ScheduleContestProgressBarTitleBox>
              <ScheduleContestProgressBarTitleBox className="box2 scheduleContest">
                <span>Ostateczny termin nadsyłania prac konkursowych</span>
              </ScheduleContestProgressBarTitleBox>
              <ScheduleContestProgressBarTitleBox className="box3 scheduleContest">
                <span>
                  Obrady
                  <br />
                  jury
                </span>
              </ScheduleContestProgressBarTitleBox>
              <ScheduleContestProgressBarTitleBox className="box4 scheduleContest">
                <span>
                  Ogłoszenie
                  <br /> zwycięzców
                </span>
              </ScheduleContestProgressBarTitleBox>
              <ScheduleContestProgressBarDate className="date1 scheduleContest ">
                12.02.2024
              </ScheduleContestProgressBarDate>
              <ScheduleContestProgressBarDate className="date2 scheduleContest">
                31.05.2024
              </ScheduleContestProgressBarDate>
              <ScheduleContestProgressBarDate className="date3 scheduleContest">
                04-07.06.2024
              </ScheduleContestProgressBarDate>
              <ScheduleContestProgressBarDate className="date4 scheduleContest">
                10.06.2024
              </ScheduleContestProgressBarDate>
            </ScheduleContestProgressBar>
          </ScheduleContestProgressBarWrapper>
        </Row>
        {/*<Row>
          <Col>
            <ScheduleContestWinnersButtonLinkWrapper>
              <ScheduleContestWinnersButtonLink
                as="a"
                href={withPrefix(
                  '/docs/Lista_zwyciezcow_BNP_Misja_Kieszonkowe.pdf'
                )}
                target="_blank"
              >
                Zobacz listę zwycięzców
              </ScheduleContestWinnersButtonLink>
            </ScheduleContestWinnersButtonLinkWrapper>
          </Col>
        </Row>
        <Row>
          <Col>
            <ScheduleContestTitle color="#91BD24" id="timetable">
              <span>W jaki sposób przesłać prace konkursową</span>
            </ScheduleContestTitle>
          </Col>
        </Row>*/}

      </ScheduleContestContainer>
    </ScheduleContestWrapper>
  )
}

export default ScheduleContest
